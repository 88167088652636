<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <HeaderOnePageTwo />
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-slider-area poss_relative" id="home">
      <div
        class="slide personal-portfolio-slider slider-style-3 slider-paralax bg_image bg_image--25 d-flex align-center"
        style=""
      >
        <BannerThree>
          <h1 class="heading-title" slot="heading-title">
            Hey, ich bin <span>Florian</span> und willkommen auf meiner Seite.
          </h1>
        </BannerThree>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="section about-area rn-section-gap bg_color--1" id="about">
      <div class="about-wrapper">
        <div class="container">
          <AboutFour>
            <img
              slot="thum-img"
              class="w-100"
              src="../../assets/images/about/about-8.jpg"
              alt="About Images"
            />
          </AboutFour>
        </div>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Service Area  -->
    <!-- <div
      class="section service-area rn-service-area rn-section-gap bg_color--5"
      id="service"
    >
      <div class="container">
        <v-row>
          <v-col lg="12">
            <div
              class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0"
            >
              <h2 class="heading-title">My Awesome Service</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </div>
    </div> -->
    <!-- Start Service Area  -->

    <!-- Start Portfolio Area -->
    <div
      class="section rn-portfolio-area rn-section-gap bg_color--1"
      id="portfolio"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title section-title--2 text-center mb--20">
              <h2 class="heading-title">Meine Projekte</h2>
              <p>
                Ich war bereits in Vergangenheit bei vielen Projekten tätig, dazu zählen:
              </p>
            </div>
          </v-col>
        </v-row>
        <PortfolioFour />
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Contact Area  -->
    <!-- <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-9.jpg"
              alt="trydo"
            />
          </Contact>
        </v-container>
      </div>
    </div> -->
    <!-- End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
import BannerThree from "../../components/slider/BannerThree";
import AboutFour from "../../components/about/AboutFour";
import ServiceThree from "../../components/service/ServiceThree";
import PortfolioFour from "../../components/portfolio/PortfolioFour";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/FooterTwo";

export default {
  components: {
    HeaderOnePageTwo,
    BannerThree,
    AboutFour,
    ServiceThree,
    PortfolioFour,
    Contact,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
