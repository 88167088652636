<template>
  <ul
    class="social-share social-style--2 color-black d-flex justify-content-start liststyle"
  >
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.url" target="_blank"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-github",
            url: "https://github.com/C0kkie",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/C0kkie",
          },
        ],
      };
    },
  };
</script>
